const users = [
  {
    path: '/users',
    name: 'users.list',
    component: () => import('@/views/users/UsersList.vue'),
  },
  {
    path: '/my-account',
    name: 'my-account',
    component: () => import('@/views/users/account-settings/AccountSettings.vue'),
  },
  {
    path: '/register/:uuid',
    name: 'user-register',
    component: () => import('@/views/users/register/UserRegister.vue'),
    meta: {
      public: true,
      layout: 'full',
    },
  },
  {
    path: '/poc/cod-bar',
    name: 'poc.cod-bar',
    component: () => import('@/views/POCCodigoDeBarras.vue'),
    meta: {
      public: true,
      layout: 'full',
    },
  },
]

export default users
